@use '/src/styles/colors.scss' as *;

.wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 16px;
  background-color: $bg-color;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
