@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as flexbox;

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.iconContainer {
  @include flexbox.flexbox-center;
  width: 46px;
  height: 46px;
  border-radius: 200px;
  background: linear-gradient(307deg, #668940 0%, #535454 48%);
}

.icon {
  width: 24px;
  height: 24px;
}

.moneyTransfer {
  display: flex;
  flex-direction: column;
}

.count {
  color: $default-gray-200;
  letter-spacing: 0.16px;
}
