@use '/src/styles/colors.scss' as *;

.button {
  span {
    color: $HEXB8FE61;
  }

  padding: 0;
  margin: 0;
  min-width: auto;
}

.dimmed {
  color: $default-gray-200;
}

.paymentMethod {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconWithText {
  display: flex;
  align-items: center;
  gap: 12px;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
