@use '/src/styles/colors.scss' as *;

.scrollContainer {
  max-height: 200px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonText {
  background: linear-gradient(
    291deg,
    $landing-green1 -14.1%,
    $landing-green2 106.43%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.availableTagsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
