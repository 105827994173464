.container {
  display: flex;
  flex-direction: column;
  width: '100%';
  min-width: 460px;
}

.addButton {
  display: flex;
  gap: 8px;
  align-items: center;
}

.rowItems {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: inherit;
}
