.bg-ale {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -0 -0;
}

.bg-alfresco {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -72px -0;
}

.bg-ambiance {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -144px -0;
}

.bg-aquaticfeast {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -216px -0;
}

.bg-artisanalcreations {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -288px -0;
}

.bg-artisancoffee {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -360px -0;
}

.bg-artisticflair {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -432px -0;
}

.bg-attentivestaff {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -504px -0;
}

.bg-australia {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -576px -0;
}

.bg-backtonature {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -648px -0;
}

.bg-bagel {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -720px -0;
}

.bg-beachfront {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -792px -0;
}

.bg-beamingface {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -864px -0;
}

.bg-beer {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -936px -0;
}

.bg-beerflights {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1008px -0;
}

.bg-beermug {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1080px -0;
}

.bg-beerselections {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1152px -0;
}

.bg-boldandspicy {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1224px -0;
}

.bg-bottlewithpoppingcork {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1296px -0;
}

.bg-brazil {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1368px -0;
}

.bg-breakfastbar {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1440px -0;
}

.bg-breakfastspread {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1512px -0;
}

.bg-breakfastvariety {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1584px -0;
}

.bg-breathtaking {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1656px -0;
}

.bg-breezylounge {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1728px -0;
}

.bg-buffetbrunch {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1800px -0;
}

.bg-buffet {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1872px -0;
}

.bg-burgers {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -1944px -0;
}

.bg-burritos {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2016px -0;
}

.bg-butcherscounter {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2088px -0;
}

.bg-butchersselection {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2160px -0;
}

.bg-cakebytheslice {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2232px -0;
}

.bg-cakes {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2304px -0;
}

.bg-caribbean {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2376px -0;
}

.bg-caninespecialties {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2448px -0;
}

.bg-celebrations {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2520px -0;
}

.bg-celebratoryfest {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2592px -0;
}

.bg-charcuterieboards {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2664px -0;
}

.bg-charming {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2736px -0;
}

.bg-cheesecake {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2808px -0;
}

.bg-central {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2880px -0;
}

.bg-chic {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -2952px -0;
}

.bg-chickennuggets {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3024px -0;
}

.bg-childfocused {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3096px -0;
}

.bg-childrenscorner {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3168px -0;
}

.bg-chill {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3240px -0;
}

.bg-chilloutspot {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3312px -0;
}

.bg-china {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3384px -0;
}

.bg-cocktailglass {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3456px -0;
}

.bg-coffee {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3528px -0;
}

.bg-coffeeaficionado {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3600px -0;
}

.bg-coffeearomas {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3672px -0;
}

.bg-coffeeblends {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3744px -0;
}

.bg-coffeehouse {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3816px -0;
}

.bg-coffeespecialties {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3888px -0;
}

.bg-comfortfood {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -3960px -0;
}

.bg-continentalbuffet {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4032px -0;
}

.bg-courteous {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4104px -0;
}

.bg-cozy {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4176px -0;
}

.bg-cozycomfort {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4248px -0;
}

.bg-craftbeers {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4320px -0;
}

.bg-croissant {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4392px -0;
}

.bg-cuddly {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4464px -0;
}

.bg-cuisine {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4536px -0;
}

.bg-cupcakes {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4608px -0;
}

.bg-danceparties {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4680px -0;
}

.bg-culinarycharm {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4752px -0;
}

.bg-dessertsampler {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4824px -0;
}

.bg-dessertwines {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4896px -0;
}

.bg-dietoptions {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -4968px -0;
}

.bg-dogfriendly {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5040px -0;
}

.bg-dash {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5112px -0;
}

.bg-donuts {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5184px -0;
}

.bg-dogthemed {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5256px -0;
}

.bg-doggyplayarea {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5328px -0;
}

.bg-dumplings {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5400px -0;
}

.bg-cozyplace {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5472px -0;
}

.bg-dancemusic {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5544px -0;
}

.bg-dogfriendlypatio {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5616px -0;
}

.bg-dog {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5688px -0;
}

.bg-eclecticmix {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5760px -0;
}

.bg-ecofriendly {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5832px -0;
}

.bg-ecofriendlyvibe {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5904px -0;
}

.bg-doggietreats {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -5976px -0;
}

.bg-elegant {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6048px -0;
}

.bg-epic {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6120px -0;
}

.bg-espressocreations {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6192px -0;
}

.bg-ethiopia {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6264px -0;
}

.bg-extravaganza {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6336px -0;
}

.bg-familyatmosphere {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6408px -0;
}

.bg-familyfriendly {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6480px -0;
}

.bg-familyoperated {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6552px -0;
}

.bg-familyowned {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6624px -0;
}

.bg-farmfreshmeat {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6696px -0;
}

.bg-farmproducts {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6768px -0;
}

.bg-farmtotable {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6840px -0;
}

.bg-fieryflavors {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6912px -0;
}

.bg-flexitarian {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -6984px -0;
}

.bg-fodmap {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7056px -0;
}

.bg-france {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7128px -0;
}

.bg-freshbread {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7200px -0;
}

.bg-freshharvest {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7272px -0;
}

.bg-freshjuices {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7344px -0;
}

.bg-freshlybrewed {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7416px -0;
}

.bg-freshmeat {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7488px -0;
}

.bg-freshseafood {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7560px -0;
}

.bg-freshwaterbowls {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7632px -0;
}

.bg-friedchicken {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7704px -0;
}

.bg-friendly {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7776px -0;
}

.bg-fries {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7848px -0;
}

.bg-fusion {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7920px -0;
}

.bg-germany {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -7992px -0;
}

.bg-glutenfree {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8064px -0;
}

.bg-grand {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8136px -0;
}

.bg-grandmasrecipes {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8208px -0;
}

.bg-greece {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8280px -0;
}

.bg-groovezone {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8352px -0;
}

.bg-halal {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8424px -0;
}

.bg-healthdrinks {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8496px -0;
}

.bg-heirloomcooking {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8568px -0;
}

.bg-herbalteas {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8640px -0;
}

.bg-hiddengem {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8712px -0;
}

.bg-homegrownmagic {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8784px -0;
}

.bg-homely {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8856px -0;
}

.bg-homemade {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -8928px -0;
}

.bg-homestylecooking {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9000px -0;
}

.bg-hospitable {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9072px -0;
}

.bg-hotandtasty {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9144px -0;
}

.bg-icecream {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9216px -0;
}

.bg-icedteas {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9288px -0;
}

.bg-impeccable {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9360px -0;
}

.bg-india {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9432px -0;
}

.bg-indonesia {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9504px -0;
}

.bg-infusedwaters {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9576px -0;
}

.bg-interactive {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9648px -0;
}

.bg-intimate {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9720px -0;
}

.bg-intimatevibe {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9792px -0;
}

.bg-ipa {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9864px -0;
}

.bg-italy {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -9936px -0;
}

.bg-japan {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10008px -0;
}

.bg-juicysteaks {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10080px -0;
}

.bg-keto {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10152px -0;
}

.bg-kidfriendly {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10224px -0;
}

.bg-kidsactivities {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10296px -0;
}

.bg-kidsarea {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10368px -0;
}

.bg-kidsplayzone {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10440px -0;
}

.bg-kosher {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10512px -0;
}

.bg-lager {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10584px -0;
}

.bg-layeredcakes {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10656px -0;
}

.bg-lebanon {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10728px -0;
}

.bg-lemonades {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10800px -0;
}

.bg-livedanceacts {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10872px -0;
}

.bg-lively {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -10944px -0;
}

.bg-livemusic {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11016px -0;
}

.bg-lobsterluxury {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11088px -0;
}

.bg-localmicrobrews {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11160px -0;
}

.bg-localtreasures {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11232px -0;
}

.bg-localwines {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11304px -0;
}

.bg-lowcarb {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11376px -0;
}

.bg-lowfat {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11448px -0;
}

.bg-malaysia {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11520px -0;
}

.bg-meatdelicacies {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11592px -0;
}

.bg-meatsandwiches {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11664px -0;
}

.bg-meatwraps {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11736px -0;
}

.bg-mediterranean {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11808px -0;
}

.bg-mediterraneandiet {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11880px -0;
}

.bg-mexico {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -11952px -0;
}

.bg-middleeast {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12024px -0;
}

.bg-milkshakes {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12096px -0;
}

.bg-minimalistchic {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12168px -0;
}

.bg-mixedcuisine {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12240px -0;
}

.bg-mocktails {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12312px -0;
}

.bg-morningbuffet {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12384px -0;
}

.bg-morocco {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12456px -0;
}

.bg-naturesbalcony {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12528px -0;
}

.bg-nohassleparking {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12600px -0;
}

.bg-oceansbounty {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12672px -0;
}

.bg-octopus {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12744px -0;
}

.bg-offthehook {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12816px -0;
}

.bg-onsiteparking {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12888px -0;
}

.bg-organicgoodies {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -12960px -0;
}

.bg-ornateglam {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13032px -0;
}

.bg-outdoorsanctuary {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13104px -0;
}

.bg-outdoorseating {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13176px -0;
}

.bg-outdoorsvibe {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13248px -0;
}

.bg-paleo {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13320px -0;
}

.bg-panoramic {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13392px -0;
}

.bg-panoramicviews {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13464px -0;
}

.bg-parking {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13536px -0;
}

.bg-parkingfacilities {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13608px -0;
}

.bg-parkingoptions {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13680px -0;
}

.bg-pastries {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13752px -0;
}

.bg-personalized {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13824px -0;
}

.bg-personalservice {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13896px -0;
}

.bg-peru {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -13968px -0;
}

.bg-pescatarian {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14040px -0;
}

.bg-petfriendly {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14112px -0;
}

.bg-picturesque {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14184px -0;
}

.bg-pilsner {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14256px -0;
}

.bg-pizza {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14328px -0;
}

.bg-pizzaslices {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14400px -0;
}

.bg-placestowork {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14472px -0;
}

.bg-plantbased {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14544px -0;
}

.bg-premiumcuts {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14616px -0;
}

.bg-professionalstaff {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14688px -0;
}

.bg-proteinshakes {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14760px -0;
}

.bg-pupfriendlymenu {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14832px -0;
}

.bg-quaint {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14904px -0;
}

.bg-qualitymeat {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -14976px -0;
}

.bg-quirky {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15048px -0;
}

.bg-rad {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15120px -0;
}

.bg-rawfood {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15192px -0;
}

.bg-redwine {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15264px -0;
}

.bg-redwines {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15336px -0;
}

.bg-roastchicken {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15408px -0;
}

.bg-romantic {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15480px -0;
}

.bg-rooftop {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15552px -0;
}

.bg-rosewines {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15624px -0;
}

.bg-russia {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15696px -0;
}

.bg-rustic {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15768px -0;
}

.bg-safeforchildren {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15840px -0;
}

.bg-scandinavia {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15912px -0;
}

.bg-scenic {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -15984px -0;
}

.bg-scenicveranda {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16056px -0;
}

.bg-seafoodplatters {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16128px -0;
}

.bg-seasideparadise {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16200px -0;
}

.bg-seasonalbrews {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16272px -0;
}

.bg-seasonalwonders {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16344px -0;
}

.bg-secluded {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16416px -0;
}

.bg-secureparking {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16488px -0;
}

.bg-serene {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16560px -0;
}

.bg-serenevibe {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16632px -0;
}

.bg-skorea {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16704px -0;
}

.bg-smoothies {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16776px -0;
}

.bg-snug {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16848px -0;
}

.bg-sommelier {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16920px -0;
}

.bg-sophisticated {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -16992px -0;
}

.bg-sophisticatedsoirees {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17064px -0;
}

.bg-spain {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17136px -0;
}

.bg-sparklingwater {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17208px -0;
}

.bg-sparklingwines {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17280px -0;
}

.bg-specialtycakes {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17352px -0;
}

.bg-specialtycoffees {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17424px -0;
}

.bg-specificcarbs {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17496px -0;
}

.bg-spicelovers {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17568px -0;
}

.bg-spicydelights {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17640px -0;
}

.bg-spicyfood {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17712px -0;
}

.bg-steaks {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17784px -0;
}

.bg-stout {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17856px -0;
}

.bg-stylishtouch {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -17928px -0;
}

.bg-sushi {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18000px -0;
}

.bg-sustainableeats {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18072px -0;
}

.bg-tacos {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18144px -0;
}

.bg-tendermeat {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18216px -0;
}

.bg-terrace {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18288px -0;
}

.bg-texmex {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18360px -0;
}

.bg-thailand {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18432px -0;
}

.bg-timehonored {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18504px -0;
}

.bg-timelessclassic {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18576px -0;
}

.bg-tonguetingling {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18648px -0;
}

.bg-traditional {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18720px -0;
}

.bg-tranquiloasis {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18792px -0;
}

.bg-treats {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18864px -0;
}

.bg-trendyaesthetics {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -18936px -0;
}

.bg-turkey {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19008px -0;
}

.bg-uk {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19080px -0;
}

.bg-ukraine {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19152px -0;
}

.bg-unforgettable {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19224px -0;
}

.bg-unwindhaven {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19296px -0;
}

.bg-upscale {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19368px -0;
}

.bg-urban {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19440px -0;
}

.bg-usa {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19512px -0;
}

.bg-valetparking {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19584px -0;
}

.bg-vegan {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19656px -0;
}

.bg-vegetarian {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19728px -0;
}

.bg-verdantbeauty {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19800px -0;
}

.bg-vibeandservice {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19872px -0;
}

.bg-vibrant {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -19944px -0;
}

.bg-vietnam {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20016px -0;
}

.bg-vintage {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20088px -0;
}

.bg-warm {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20160px -0;
}

.bg-warmhospitality {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20232px -0;
}

.bg-waterdelicacies {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20304px -0;
}

.bg-waterfront {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20376px -0;
}

.bg-welcoming {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20448px -0;
}

.bg-wheatbeer {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20520px -0;
}

.bg-whimsical {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20592px -0;
}

.bg-whitewines {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20664px -0;
}

.bg-wilderness {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20736px -0;
}

.bg-winebytheglass {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20808px -0;
}

.bg-winecellar {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20880px -0;
}

.bg-winepairings {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -20952px -0;
}

.bg-wineselections {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21024px -0;
}

.bg-zenvibes {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21096px -0;
}

.bg-zesty {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21168px -0;
}

.bg-hk {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21240px -0;
}

.bg-herbal_tea {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21312px -0;
}

.bg-fusion_tea {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21384px -0;
}

.bg-fruit_tea {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21456px -0;
}

.bg-fish_ball {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21528px -0;
}

.bg-char_siu {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21600px -0;
}

.bg-noodles {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21672px -0;
}

.bg-hot_pot {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21744px -0;
}

.bg-roast_goose {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21816px -0;
}

.bg-rice {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21888px -0;
}

.bg-buffet {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -21960px -0;
}

.bg-shampan {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -22032px -0;
}

.bg-british_tea {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -22104px -0;
}

.bg-bubble_tea {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -22176px -0;
}

.bg-snow {
  width: 72px;
  height: 72px;
  background: url('css_sprites.png') -22248px -0;
}