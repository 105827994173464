@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as *;

.cardsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}

.cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 1286px) {
    max-width: 600px;
  }
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerContainer {
  padding-top: 40px;
  padding-bottom: 28px;
  width: 100%;
  max-width: 1224px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.topCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 1266px) {
    flex-wrap: wrap;
  }
}

.totalActivityWrapper {
  width: 100%;

  @media (min-width: 1266px) {
    max-width: calc(100% - 520px - 24px);
  }
}

.currentBoostWrapper {
  padding: 10px 16px;
  background-color: $bg-color;
  border-radius: 60px;
  display: flex;
  gap: 34px;
}

.currentBoostValue {
  @include addGradientToText(to left, $gr-landing);
}

.getBoostWrapper {
  width: 100%;
  position: relative;
  left: 78px;

  @media (min-width: 1266px) {
    max-width: 520px;
  }

  @media (max-width: 1266px) {
    left: 0;
  }
}

.getBoostWrapperStatic {
  left: 0;
}

.foodTrackLocationWrapper {
  width: 678px;

  @media (max-width: 1266px) {
    width: 100%;
  }
}
