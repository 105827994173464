@use '/src/styles/colors.scss' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.titleBlock {
  display: flex;
  align-items: center;
  gap: 8px;
}

.title {
  color: $default-gray-100;
}

.color {
  padding: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.statsBlock {
  color: $white;
  display: flex;
  gap: 8px;
  align-items: center;
}
