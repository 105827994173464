@use '/src/styles/colors.scss' as *;
@use '/src/styles/mixins.scss' as flexbox;

.wrapper {
  background-color: $bg-color;
  border-radius: 6px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.title {
  font-weight: 500;
  color: $default-gray-200;
}

.buttonsContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.button {
  &.active {
    background: linear-gradient(270deg, $gr-landing);
    color: $default-gray-900;
  }
}

.listBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.subtitle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $default-gray-700;
}

.rowsBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.emptyBlock {
  @include flexbox.flexbox-center;
  padding: 100px 0 181px;
}
