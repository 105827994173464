@use "/src/styles/colors.scss" as *;


.appContainer {
  padding: 40px 0px;
}

.highchartsContainer {
  margin-top: 40px;
  margin-bottom: 20px;
}

.wireframesChart {
  background-color: $bg-color;
  border-radius: 16px;
  margin-top: 24px;
}

.viewOnlyGraphFlex {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.viewOnlyGraph {
  background-color: $bg-color;
  border-radius: 8px;
  flex: 1 1;
  height: 136px;
}

.speedometer {
  width: 400px;
  height: 200px;
  margin: 20px auto;
  overflow: clip;
  padding: 0px 45px;
}