@use '/src/styles/colors.scss' as *;

.percentBlock {
  display: flex;
  align-items: baseline;
  gap: 4px;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 11px solid $HEXB8FE61;

  &.negative {
    border-top: 11px solid $profile-red;
    border-bottom: 0;
  }

  &.zero {
    border-top: 11px solid $default-gray-300;
    border-bottom: 0;
  }
}

.percent {
  font-size: 16px;
  color: $HEXB8FE61;

  &.negative {
    color: $profile-red;
  }

  &.zero {
    color: $default-gray-300;
  }
}
