$bg: #101010;
$bg-color: #1b1b1b;

$white: #fff;
$profile-purple: #9c4aee;
$profile-lemon: #e5d801;
$profile-blue: #32ffff;
$profile-red: #ff482c;
$profile-orange: #fe9c0a;

$default-gray-50: #f3f3f3;
$default-gray-100: #e7e7e7;
$default-gray-200: #c3c3c3;
$default-gray-300: #9f9f9f;
$default-gray-400: #585858;
$default-gray-500: #4f4f4f;
$default-gray-600: #444;
$default-gray-700: #262626;
$default-gray-900: #080808;

$default-green-100: #e7e7e7;
$default-green-200: #d7f4cc;
$default-green-300: #beedae;
$default-green-400: #8ee071;
$default-green-500: #5dd334;
$default-green-600: #54be2f;
$default-green-700: #469e27;
$default-green-800: #387f1f;
$default-green-900: #2e6719;

$tw-green-300: #bef264;
$tw-yellow-400: #facc15;

$HEXA9E979: #a9e979;
$HEXFAFD74: #fafd74;
$HEXBCFF83: #bcff83;
$HEXB8FE61: #b8fe61;
$HEX2C2C2E: #2c2c2e;
$HEXEBEBF599: #ebebf599;
$HEX08080899: #08080899;
$HEXb8fe61b2: #b8fe61b2;
$HEX1b2a07: #1b2a07;
$HEXB8FE619E: #b8fe619e;
$HEXF3F4F64D: #f3f4f64d;
$HEXe7e52a99: #e7e52a99;

$manz: #dbf069;
$shark: #28282b;

$chateau-green: #39b04a;
$apple: #5aba43;
$atlantis: #9dce34;
$pear: #cedd2a;
$golden-dream: #ece623;
$ripe-lemon: #f7e921;

$linear-green1: #fff9614f;
$linear-green2: #34d39900;

$linear-red-unlikes: #ff482c8c;
$linear-red1: #ff482c40;
$linear-red2: #ff482c00;

$landing-green1: #34d399;
$landing-green2: #fff961;

// Gradient

$gr-landing: $landing-green1 0%, $landing-green2 100%;
$gr-for-buttons: $HEXF3F4F64D 0%, $HEXB8FE619E 100%;

:export {
  bg: $bg;
  bg-color: $bg-color;
  white: $white;
  profile-purple: $profile-purple;
  profile-lemon: $profile-lemon;
  profile-blue: $profile-blue;
  profile-red: $profile-red;
  profile-orange: $profile-orange;
  default-gray-100: $default-gray-100;
  default-gray-200: $default-gray-200;
  default-gray-300: $default-gray-300;
  default-gray-400: $default-gray-400;
  default-gray-500: $default-gray-500;
  default-gray-600: $default-gray-600;
  default-gray-700: $default-gray-700;
  default-gray-900: $default-gray-900;

  gr-landing: $gr-landing;
  gr-for-buttons: $gr-for-buttons;
  linear-red-unlikes: $linear-red-unlikes;
  linear-red1: $linear-red1;
  linear-red2: $linear-red2;

  default-green-100: $default-green-100;
  default-green-200: $default-green-200;
  default-green-300: $default-green-300;
  default-green-400: $default-green-400;
  default-green-500: $default-green-500;
  default-green-600: $default-green-600;
  default-green-700: $default-green-700;
  default-green-800: $default-green-800;
  default-green-900: $default-green-900;

  tw-green-300: $tw-green-300;
  tw-yellow-400: $tw-yellow-400;

  linear-green1: $linear-green1;
  linear-green2: $linear-green2;
  landing-green1: $landing-green1;
  landing-green2: $landing-green2;
  HEXB8FE61: $HEXB8FE61;
  HEX1b2a07: $HEX1b2a07;
  HEXb8fe61b2: $HEXb8fe61b2;
  HEXB8FE619E: $HEXB8FE619E;
  HEXF3F4F64D: $HEXF3F4F64D;
  HEXFAFD74: $HEXFAFD74;
  HEXBCFF83: $HEXBCFF83;
  HEXA9E979: $HEXA9E979;
  HEX2C2C2E: $HEX2C2C2E;
  HEXEBEBF599: $HEXEBEBF599;

  manz: $manz;
  shark: $shark;

  chateau-green: $chateau-green;
  apple: $apple;
  atlantis: $atlantis;
  pear: $pear;
  golden-dream: $golden-dream;
  ripe-lemon: $ripe-lemon;
}
