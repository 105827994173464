@use '/src/styles/colors.scss' as *;

.wrapper {
  width: 100%;
  border-radius: 16px;
  background-color: $bg-color;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.recentActivityBlock {
  padding: 24px 24px 0 24px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerSubtitle {
  color: $default-gray-200;
}
